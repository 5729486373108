@media (max-width: 600px) {
    .section {
        padding: 0 10px;
    }
}

.title {
    margin: 15px 5px 30px;

    font-size: 28px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}

.section h4 {
    font-size: 20px;
    margin-bottom: 10px;
}

.section > p {
    font-size: 16px;
    color: #999999;
}

.section > div {
    margin-top: 30px;
    padding: 40px 20px;

    text-align: center;

    background-color: #e8e8e8;
    border-radius: 20px;
}

.section > div span {
    color: #084a73;
    cursor: pointer;
}

.section > div span:hover {
    text-decoration: underline;
}
