*,
*:before,
*:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background: #fff;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.container {
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
    flex: 1;
}

.slick-dots {
    bottom: 10px;
    /*margin-bottom: 30px;*/
}

.slick-dots li {

}

.slick-dots li button::before {
    content: '';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    opacity: 1;
}

.slick-dots .slick-active button::before {
    background-color: white;
}


