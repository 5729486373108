.title {
    margin: 15px 5px 30px;

    font-size: 28px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}

.section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 50px;
}

@media (max-width: 600px) {
    .section {
        padding: 0 10px;
        grid-template-columns: 1fr;
    }
}

.section div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.section div img {
    width: 80%;
}

section div span {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
}
