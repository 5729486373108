.header {
    position: sticky;
    top: 0;
    width: 100%;

    background: #f7f7f7;
    border-bottom: 1px solid #777;

    z-index: 10;
}

.headerTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
}

@media (max-width: 600px) {
    .headerTop {
        flex-direction: column;
    }
}

.logo {
    width: 300px;
}

.buttons {
    display: flex;
    flex-direction: column;
}

.buttons a {
    width: 230px;
    display: block;
    padding: 3px 10px;
    color: #333;
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;

    border: 2px solid rgba(42,83,101,0.5);
    border-radius: 10px;

    transition: border .2s ease-in-out, color .2s ease-in-out;
}

.buttons a:first-child {
    margin-bottom: 3px;
}

.buttons a:hover {
    border: 2px solid #2a5365;
    color: #000;
}

.navWrapper {
    background-color: #fff;
    border-top: 1px solid #777;
}

@media (max-width: 600px) {
    .navWrapper {
        display: none;
    }
}

.nav {
    display: flex;
    justify-content: center;
    list-style-type: none;
}

.nav a {
    display: block;
    padding: 0 10px;
    font-size: 15px;
    font-weight: bold;
    line-height: 40px;
    text-transform: uppercase;
    text-decoration: none;
    color: #34657a;

    transition: color .2s ease-in-out;
}

.nav a:hover,
.nav :global(a.active) {
    color: #000;
}

.menu {
    display: none;
    margin-top: 5px;
    margin-bottom: -5px;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

@media (max-width: 600px) {
    .menu {
        display: block;
    }
}

.menu img {
    width: 40px;
}
