.title {
    margin: 15px 5px 30px;

    font-size: 28px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}

.section {
    display: flex;
    justify-content: space-between;
}

.section > div {
    width: 40%;
}

@media (max-width: 600px) {
    .section {
        flex-direction: column;
        padding: 0 10px;
    }

    .section > div {
        width: 100%;
    }
}

.contacts div {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.contacts h4 {
    margin: 0 0 10px 0;
    font-size: 16px;
}

.contacts a {
    font-size: 16px;
    text-decoration: none;
    color: #212529;
}

.contacts a:hover {
    text-decoration: underline;
}
