.section {
    padding: 70px 0;
    font-size: 16px;
}

@media (max-width: 600px) {
    .section {
        padding: 70px 10px;
    }
}

.sectionBGBlue {
    background: linear-gradient(180deg, rgba(223, 242, 251, 1) 0%, rgba(233, 233, 233, 1) 100%);
}

.sectionBGLight {
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(223, 242, 251, 1) 100%);
}

.section h4 {
    margin-bottom: 20px;
}

.section ul {
    margin-left: 15px;
}

.section ol {
    margin-left: 40px;
}

.sectionTitle {
    text-align: center;
    font-weight: bold;
}

.sectionGoals ul {
    list-style: none;
}

.sectionGoals ul li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.sectionGoals ul img {
    width: 36px;
    margin-right: 10px;
}

.center {
    text-align: center;
}
