@media (max-width: 600px) {
    .section {
        padding: 0 10px;
    }
}

.title {
    margin: 15px 5px 30px;

    font-size: 28px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}

.section > div {
    margin-bottom: 15px;
}

.section h4 {
    margin-bottom: 5px;
}

.section ol {
    margin-left: 40px;
}

.section ol span:first-child {
    margin-right: 5px;
    text-decoration: underline;
}
